import { ChevronRight as ChevronRightIcon } from "@mui/icons-material";
import { Alert, AppBar, Grid, Link, Tab, Tabs } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { TabPanel } from "components/TabPanel";
import TabToolbarPlaceholder from "components/TabToolbarPlaceholder";
import { isEmpty } from "helpers";
import { useAppDispatch, useAppSelector } from "store";
import { setAppBar } from "store/slices/menu.store";

import { RelatedJobsTab } from "components/job/RelatedJobsTab";
import { JobDetailTab } from "components/job/details/JobDetailTab";
import { FilesTab } from "components/job/files/FilesTab";
import { NotesTab } from "components/job/notes/NotesTab";
import { RequestedPartsTab } from "components/job/parts/RequestedPartsTab";
import { TasksTab } from "components/job/tasks/TasksTab";

import { VisitsTab } from "components/job/visit/VisitsTab";
import { selectLoadingRelatedJobs, selectPageSelectedJob } from "store/root.store";
import theme from "theme";
import { ChecklistTab } from "./visit/ChecklistTab";

export const JobDetails: FC = () => {
  const job = useAppSelector(selectPageSelectedJob);
  const loadingRelatedJobs = useAppSelector(selectLoadingRelatedJobs);
  const { workNotes, files, visits, relatedJobs } = job;
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [tabValue, setTabValue] = useState("details");
  const [hideRelatedJobsNotification, setHideRelatedJobsNotification] = useState(true);

  //FAB
  const [fab, setFab] = useState<JSX.Element>();

  useEffect(() => {
    dispatch(
      setAppBar({
        title: job?.id ?? "",
        goingBack: true,
      })
    );
  }, [dispatch, job]);

  useEffect(() => {
    if (!isEmpty(relatedJobs)) {
      setHideRelatedJobsNotification(false);
    }
  }, [relatedJobs]);

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          mt: 7,
          backgroundColor: `primary.main`,
        }}
      >
        <Tabs
          scrollButtons
          value={tabValue}
          onChange={(_, newValue: string) => {
            setTabValue(newValue);
          }}
          variant="scrollable"
          textColor="inherit"
        >
          <Tab
            label={intl.formatMessage({ id: "general.details" })}
            className="e2e-details-tab"
            value="details"
          />
          {!isEmpty(job.checklists) && (
            <Tab
              label={intl.formatMessage({ id: "job.checklists" })}
              className="e2e-checklists-tab"
              value="checklists"
            />
          )}
          {!isEmpty(files) && (
            <Tab
              label={intl.formatMessage({ id: "general.files" })}
              className="e2e-files-tab"
              value="files"
            />
          )}
          {!isEmpty(workNotes) && (
            <Tab label={intl.formatMessage({ id: "job.notes" })} value="notes" />
          )}
          {!isEmpty(visits) && (
            <Tab
              label={intl.formatMessage({ id: "general.visits" })}
              className="e2e-visits-tab"
              value="visits"
            />
          )}
          {!isEmpty(job.preOrderedParts) && (
            <Tab
              label={intl.formatMessage({ id: "part.requestedParts" })}
              className="e2e-requestedparts-tab"
              value="requestedParts"
            />
          )}
          {!isEmpty(job.tasks) && (
            <Tab label={intl.formatMessage({ id: "job.tasks" })} value="tasks" />
          )}
          {!isEmpty(relatedJobs) && (
            <Tab
              label={intl.formatMessage({ id: "general.relatedJobs" })}
              className="e2e-relatedjobs-tab"
              value="relatedJobs"
            />
          )}
        </Tabs>
      </AppBar>
      <TabToolbarPlaceholder />

      {!hideRelatedJobsNotification && !isEmpty(relatedJobs) && (
        <Link
          component="button"
          onClick={() => setTabValue("relatedJobs")}
          sx={{
            width: 1,
          }}
          data-testid="JobDetails-RelatedJobs"
        >
          <Alert
            severity="info"
            sx={{
              width: 1,
              borderRadius: 0,
              backgroundColor: "info.main",
              color: "common.white",
            }}
            action={<ChevronRightIcon />}
          >
            <FormattedMessage id="general.otherJobsOpen" />
          </Alert>
        </Link>
      )}

      <TabPanel index="details" value={tabValue}>
        <JobDetailTab />
      </TabPanel>
      <TabPanel index="files" value={tabValue}>
        <FilesTab />
      </TabPanel>
      <TabPanel index="notes" value={tabValue}>
        <NotesTab />
      </TabPanel>
      <TabPanel index="tasks" value={tabValue}>
        <TasksTab workTasks={job.tasks} />
      </TabPanel>
      <TabPanel index="checklists" value={tabValue}>
        <ChecklistTab setFab={setFab} />
      </TabPanel>
      <TabPanel index="visits" value={tabValue}>
        <VisitsTab />
      </TabPanel>
      <TabPanel index="requestedParts" value={tabValue}>
        <RequestedPartsTab parts={job.preOrderedParts} />
      </TabPanel>
      <TabPanel index="relatedJobs" value={tabValue}>
        <RelatedJobsTab
          jobs={relatedJobs}
          loading={loadingRelatedJobs}
          hideNotification={setHideRelatedJobsNotification}
          customerId={job.customer?.id}
          equipmentId={job.equipment?.id as string}
        />
      </TabPanel>
      <Grid
        container
        direction="row"
        spacing={1}
        width="100%"
        wrap="nowrap"
        alignItems="center"
        sx={{ position: "fixed", bottom: "0px", margin: 0, paddingRight: theme.spacing(1) }}
      >
        <Grid item>{fab}</Grid>
      </Grid>
    </>
  );
};
