import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import {
  AppBar,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "store";
import { selectPageSelectedJob } from "store/root.store";
import {
  filterNewJobIds,
  handleCloseRejectJob,
  rejectJob,
  selectRejectJobLoading,
  selectRejectJobOpen,
  selectRejectJobText,
  setRejectJobText,
} from "store/slices/jobs.store";

import { unwrapResult } from "@reduxjs/toolkit";
import BackdropPrimaryMain from "components/BackdropPrimaryMain";
import PrimaryButton from "components/PrimaryButton";
import { StyledContainer } from "components/StyledContainer";
import StyledTextField from "components/StyledTextField";
import Transition from "components/Transition";
import { isAbortError } from "helpers";
import { addSnackbarMessage } from "store/slices/snackbar.store";

export const RejectJobDialog: FC = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const job = useAppSelector(selectPageSelectedJob);
  const loading = useAppSelector(selectRejectJobLoading);
  const open = useAppSelector(selectRejectJobOpen);
  const text = useAppSelector(selectRejectJobText);
  const navigate = useNavigate();

  const handleClose = () => {
    dispatch(handleCloseRejectJob());
    navigate("/jobs");
  };

  const rejectJobAssignment = () => {
    dispatch(
      rejectJob({
        jobId: job.id,
        rejectReason: text,
      })
    )
      .then(unwrapResult)
      .then(({ queued }) => {
        dispatch(filterNewJobIds({ jobId: job.id }));
        navigate("/jobs");
        if (queued) {
          dispatch(addSnackbarMessage({ key: "RejectJob-stored" }));
        } else {
          dispatch(addSnackbarMessage({ key: "RejectJob-success" }));
        }
      })
      .catch((e) => {
        if (isAbortError(e)) return;
        dispatch(addSnackbarMessage({ key: "RejectJob-fail" }));
      });
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      data-testid="rejectJobDialog"
    >
      <BackdropPrimaryMain open={loading}>
        <CircularProgress color="inherit" />
      </BackdropPrimaryMain>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              dispatch(handleCloseRejectJob());
            }}
            aria-label={intl.formatMessage({ id: "general.back" })}
            size="large"
            data-testid="rejectJobCloseButton"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography className="AppBar-label" paddingRight="36px">
            {`${job?.id} `} / <FormattedMessage id="general.rejectJob" />
          </Typography>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <StyledContainer>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <StyledTextField
              data-testid="rejectJobTextField"
              value={text}
              label={intl.formatMessage({ id: "rejectJob.specifyReason" })}
              onChange={(e) => dispatch(setRejectJobText({ text: e.target.value }))}
              multiline
              rows={15}
            />
          </Grid>
          <Grid item>
            <PrimaryButton
              key={`${!text || loading}`}
              disabled={!text || loading}
              fullWidth
              onClick={rejectJobAssignment}
            >
              <FormattedMessage id="general.confirm" />
            </PrimaryButton>
          </Grid>
        </Grid>
      </StyledContainer>
    </Dialog>
  );
};
