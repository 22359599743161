import { FC, useCallback } from "react";
import { useIntl } from "react-intl";

import { useAppDispatch, useAppSelector } from "store";
import { setAppBar } from "store/slices/menu.store";
import { selectSelectedJob } from "store/slices/jobs.store";

import { useEffectOnce } from "helpers/useEffectOnce";
import { JobVisit } from "./visit/JobVisit";

export const JobVisitPage: FC = () => {
  const dispatch = useAppDispatch();
  const job = useAppSelector(selectSelectedJob);
  const { selectedJobId, jobVisits } = useAppSelector((s) => s.jobs);
  const intl = useIntl();

  const init = useCallback(async () => {
    await dispatch(
      setAppBar({
        title: `${job.id} / ${intl.formatMessage({
          id: "general.visit",
        })}`,
        goingBack: true,
      })
    );
  }, [dispatch, intl, job]);
  useEffectOnce(() => {
    init();
  });

  return selectedJobId && jobVisits[selectedJobId] ? <JobVisit /> : null;
};
