import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AppBar, Dialog, IconButton, Toolbar, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { StyledContainer } from "components/StyledContainer";
import Transition from "components/Transition";

import { TravelTab } from "pages/jobs/jobDetails/visit/times/TravelTab";
import { useAppSelector } from "store";
import { selectPageSelectedJob } from "store/root.store";

interface TravelDialogProps {
  open: boolean;
  handleClose: () => void;
}

export const TravelDialog: React.FC<TravelDialogProps> = (props: TravelDialogProps) => {
  const intl = useIntl();
  const job = useAppSelector(selectPageSelectedJob);

  return (
    <Dialog
      fullScreen
      open={props.open}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          props.handleClose();
        }
      }}
      TransitionComponent={Transition}
      data-testid="travelDialog"
    >
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={props.handleClose}
            aria-label={intl.formatMessage({ id: "general.back" })}
            size="large"
            data-testid="travelDialogCloseButton"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography className="AppBar-label" paddingRight="36px">
            {`${job?.id} `} / <FormattedMessage id="visit.travel" />
          </Typography>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <StyledContainer>
        <TravelTab handleClose={props.handleClose} />
      </StyledContainer>
    </Dialog>
  );
};
